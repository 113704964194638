<template>
  <section class="invoice-preview-wrapper">

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    :src="appLogoImage"
                    width="40"
                    height="40"
                    alt="logo"
                  />

                  <h2 class="brand-text invoice-logo text-primary">
                    {{ appName }}
                  </h2>
                </div>
                <p class="card-text mb-25">
                  {{ invoice.devis.transporteur === null ? invoice.devis.societe.adresse : invoice.devis.transporteur.adresse }}
                </p>
                <p class="card-text mb-25">
                  {{ invoice.devis.transporteur === null ? invoice.devis.societe.email : invoice.devis.transporteur.email }}
                </p>
                <p class="card-text mb-0">
                  {{ invoice.devis.transporteur === null ? invoice.devis.societe.telephone : invoice.devis.transporteur.telephone }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Facture n°
                  <span class="invoice-number"> {{ invoice.code }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Facture du :
                  </p>
                  <p class="invoice-date">
                    {{ invoice.created_at }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Etat Facture :
                  </p>
                  <p class="invoice-date">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :variant="invoice.etat === 'PROFORMA' ? 'primary' : 'success'"
                      class="float-right px-5"
                      size="sm"
                    >
                      {{ invoice.etat }}
                    </b-button>
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="3"
                class="p-0"
              >
                <h6 class="mb-2 text-primary">
                  Facture à :
                </h6>
                <h6 class="mb-25" />
                <p
                  class="card-text mb-25"
                  v-text="invoice.offre.affreteur.firstname + ' ' + invoice.offre.affreteur.lastname"
                />
                <p
                  class="card-text mb-25"
                  v-text="invoice.offre.affreteur.adresse"
                />
                <p
                  class="card-text mb-25"
                  v-text="invoice.offre.affreteur.telephone"
                />
                <p
                  class="card-text mb-0"
                  v-text="invoice.offre.affreteur.email"
                />
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="9"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2 text-primary">
                    Informations :
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Code Offre :
                        </td>
                        <td><span
                          class="font-weight-bold"
                          v-text="invoice.offre.code"
                        /></td>
                      </tr>

                      <tr>
                        <td class="pr-1">
                          Adresse de départ :
                        </td>
                        <td v-text="invoice.offre.ville_depart.name + ' ( ' + invoice.offre.ville_depart.departement.name +' )'" />
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Adresse de livraison :
                        </td>
                        <td v-text="invoice.offre.ville_destination.name + ' ( ' + invoice.offre.ville_destination.departement.name +' )'" />
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Poids Marchandise :
                        </td>
                        <td
                          class="h6"
                          v-text="(invoice.offre.poids_marchandise + ' ' + invoice.offre.unite_mesure.libelle)"
                        />
                      </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col
                md="12"
                class="p-0"
              >
                <table class="mt-1">
                  <tbody>
                    <tr>
                      <td class="pr-1 text-primary">
                        Transporteur :
                      </td>
                      <td
                        class="h6 "
                        v-text="invoice.devis.transporteur === null ? invoice.devis.transporteur_interne.prenom + ' ' + invoice.devis.transporteur_interne.nom : invoice.devis.transporteur.firstname + ' ' + invoice.devis.transporteur.lastname"
                      />
                    </tr>
                  </tbody>
                </table>

              </b-col>
              <b-col
                md="12"
                class="p-0"
              >
                <table class="mt-1">
                  <tbody>
                    <tr>
                      <td class="pr-1 text-primary">
                        Moyen de transport :
                      </td>
                      <td
                        class="h6 "
                        v-text="invoice.devis.moyen_transport.libelle"
                      />
                    </tr>
                  </tbody>
                </table>

              </b-col>
              <b-col
                md="12"
                class="p-0"
              >
                <table class="mt-1">
                  <tbody>
                    <tr>
                      <td class="pr-1 text-primary">
                        Marchandise :
                      </td>
                      <td
                        class="h6 "
                        v-text="invoice.offre.marchandise.name"
                      />
                    </tr>
                  </tbody>
                </table>

              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="[{ key: 'description', label: 'Description' }, { key: 'date_prev_depart', label: 'Date Départ Prév' }, { key: 'date_prev_livraison', label: 'Date Arrivée Prév' }, { key: 'montant', label: 'Total HT' }]"
          />
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Facture créée par :</span>
                  <span
                    class="ml-75"
                    v-text="invoice.devis.transporteur === null ? invoice.devis.societe.firstname + ' ' + invoice.devis.societe.lastname : invoice.devis.transporteur.firstname + ' ' + invoice.devis.transporteur.lastname"
                  />
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total HT:
                    </p>
                    <p
                      class="invoice-total-amount"
                      v-text="`${invoice.devis.montant.toLocaleString('fr-FR', { maximumFractionDigits: 2 })} ${invoice.devis.devise.libelle}`"
                    />
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      TVA ( 18% ):
                    </p>
                    <p
                      class="invoice-total-amount"
                      v-text="`${invoice.devis.tva.toLocaleString('fr-FR', { maximumFractionDigits: 2 }) } ${invoice.devis.devise.libelle}`"
                    />
                  </div>

                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total TTC :
                    </p>
                    <p
                      class="invoice-total-amount"
                      v-text="`${invoice.devis.total_ttc.toLocaleString('fr-FR', { maximumFractionDigits: 2 })} ${invoice.devis.devise.libelle}`"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            size="sm"
            block
            @click="printInvoice"
          >
            <feather-icon icon="PrinterIcon" />
            Imprimer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-75"
            size="sm"
            block
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>

        </b-card>
      </b-col>

    </b-row>

  </section>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import useInvoices from '@/services/invoice/invoiceService'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BTableLite,
    BCardText,
    BButton,
  },
  setup() {
    const invoiceData = ref({})
    const paymentDetails = ref({})
    const invoiceDescription = ref([])
    const { appName, appLogoImage } = $themeConfig.app

    const {
      getInvoice, invoice,
    } = useInvoices()
    onMounted(async () => {
      await getInvoice()
      invoiceDescription.value = [
        {
          description: 'Frais d\'expédition',
          date_prev_depart: invoice.value.devis.date_prev_depart,
          date_prev_livraison: invoice.value.devis.date_prev_livraison,
          montant: `${invoice.value.devis.montant} ${invoice.value.devis.devise.libelle}`,
        },
      ]
      console.clear()
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      invoice,
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
